.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.contact-header {
  text-align: center;
  margin-bottom: 40px;
}

.contact-header h1 {
  font-size: 2.5rem;
  color: #1a2b3c;
  margin-bottom: 15px;
}

.contact-divider {
  height: 4px;
  width: 80px;
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  margin: 0 auto;
  border-radius: 2px;
}

.contact-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.contact-info-section h2,
.contact-form-section h2 {
  font-size: 1.8rem;
  color: #1a2b3c;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.contact-info-section h2::after,
.contact-form-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  border-radius: 2px;
}

.contact-info-section p {
  color: #475569;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.contact-info-items {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.contact-info-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.contact-icon {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  flex-shrink: 0;
}

.contact-detail {
  flex: 1;
}

.contact-detail h3 {
  font-size: 1.2rem;
  color: #1a2b3c;
  margin-bottom: 5px;
}

.contact-detail p {
  margin: 0;
  color: #475569;
  font-size: 1rem;
  line-height: 1.5;
}

.contact-detail a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-detail a:hover {
  color: #b4941f;
  text-decoration: underline;
}

.contact-note {
  margin-top: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  border-left: 4px solid #d4af37;
  border-radius: 4px;
  font-size: 0.95rem;
  color: #475569;
}

.contact-form-section {
  background: white;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 600;
  color: #1a2b3c;
  font-size: 1rem;
}

.form-group input,
.form-group textarea {
  padding: 12px 16px;
  border: 2px solid #e1e8ed;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #d4af37;
  box-shadow: 0 0 0 4px rgba(212, 175, 55, 0.12);
  outline: none;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(212, 175, 55, 0.3);
  margin-top: 10px;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(212, 175, 55, 0.4);
}

.form-success-message {
  background: #e8f5e9;
  color: #2e7d32;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 768px) {
  .contact-content {
    grid-template-columns: 1fr;
  }
  
  .contact-form-section {
    order: -1;
  }
  
  .contact-header h1 {
    font-size: 2rem;
  }
}