.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.about-header {
  text-align: center;
  margin-bottom: 40px;
}

.about-header h1 {
  font-size: 2.5rem;
  color: #1a2b3c;
  margin-bottom: 15px;
}

.about-divider {
  height: 4px;
  width: 80px;
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  margin: 0 auto;
  border-radius: 2px;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about-intro {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #475569;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.about-section {
  margin-bottom: 30px;
}

.about-section h2 {
  font-size: 1.8rem;
  color: #1a2b3c;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

/* .about-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  border-radius: 2px;
} */

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.service-card {
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.service-card h3 {
  font-size: 1.2rem;
  color: #1a2b3c;
  margin-bottom: 10px;
}

.service-card p {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.6;
}

.values-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.value-item {
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.value-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.value-item h3 {
  font-size: 1.2rem;
  color: #1a2b3c;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 10px;
}

.value-item h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
  border-radius: 2px;
}

.value-item p {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .services-grid,
  .values-container {
    grid-template-columns: 1fr;
  }
  
  .about-header h1 {
    font-size: 2rem;
  }
}