.upload-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }
  
  .upload-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .file-upload-area {
    border: 2px dashed #e1e8ed;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    transition: all 0.3s ease;
    background-color: #f8fafc;
    position: relative;
  }
  
  .file-upload-area:hover {
    border-color: #d4af37;
    background-color: rgba(212, 175, 55, 0.05);
  }
  
  .file-upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 1.1rem;
    color: #64748b;
    cursor: pointer;
    padding: 40px 0;
  }
  
  .file-icon {
    font-size: 2.5rem;
    color: #94a3b8;
    margin-bottom: 8px;
  }
  
  .upload-text {
    text-align: center;
  }
  
  .file-size-limit {
    font-size: 0.85rem;
    color: #94a3b8;
    margin-top: 8px;
  }
  
  .file-upload-label.disabled .file-icon {
    color: #cbd5e1;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .file-info {
    margin-top: 15px;
    padding: 15px;
    background-color: #f1f5f9;
    border-radius: 8px;
  }
  
  .file-info p {
    margin: 5px 0;
    color: #475569;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .form-group label {
    font-weight: 600;
    color: #1a2b3c;
  }
  
  .form-group input {
    padding: 14px;
    border: 2px solid #e1e8ed;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #d4af37;
    box-shadow: 0 0 0 4px rgba(212, 175, 55, 0.12);
    outline: none;
  }
  
  .form-group small {
    color: #64748b;
    font-size: 0.85rem;
  }
  
  .progress-container {
    margin: 20px 0;
  }
  
  .progress-bar {
    width: 100%;
    height: 12px;
    background-color: #f1f5f9;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background: linear-gradient(90deg, #2fb22c, #39ab2f);
    border-radius: 6px;
    transition: width 0.3s ease;
  }
  
  .progress-text {
    text-align: center;
    margin-top: 8px;
    font-size: 0.9rem;
    color: #475569;
    font-weight: 500;
  }
  
  .upload-button {
    background: linear-gradient(135deg, #d4af37 0%, #f4cd68 100%);
    color: #1a2b3c;
    font-weight: 600;
    padding: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(212, 175, 55, 0.2);
  }
  
  .upload-button:hover{
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(40, 167, 69, 0.3);
  }


  .upload-button:disabled {
    background: #e2e8f0;
    color: #94a3b8;
    cursor: not-allowed;
  }
  
  .consent-reminder {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 8px;
    border-left: 2px solid #64748b;
  }
  
  .info-icon {
    color: #64748b;
    font-size: 1.2rem;
    flex-shrink: 0;
  }
  
  /*  */
  
  .consent-reminder p {
    margin: 0;
    color: #475569;
    font-size: 0.9rem;
  }
  
  .text-button {
    background: #ffffff; 
    border: none;
    color: #1a2b3c;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline;
    width: auto;
    border-radius: 4px;
  }
  
  .text-button:hover {
    /* background: #d4af37; */
    color: #1a2b3c;
  }
  
  .consent-reminder {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    background-color: #f8fafc;
    border-radius: 8px;
    border-left: 3px solid #d4af37;
  }
  
  .info-icon {
    color: #d4af37;
    font-size: 1.2rem;
    flex-shrink: 0;
  }
  
  .consent-reminder p {
    margin: 0;
    color: #475569;
    font-size: 0.9rem;
  }
  


  
  .success-notification {
    background-color: #e8f5e9;
    /* color: #2e7d32; */
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
    animation: fadeIn 0.3s ease;
  }
  
  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
    animation: fadeIn 0.3s ease;
  }
  
  .payment-section {
    margin-top: 30px;
    animation: fadeIn 0.4s ease;
  }
  
  .payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .payment-header h3 {
    font-size: 1.5rem;
    color: #1a2b3c;
    margin: 0;
  }
  
  .payment-badge {
    background: linear-gradient(135deg, #28a745 0%, #20c997 100%);
    color: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  .payment-card {
    background: white;
    border-radius: 16px;
    padding: 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    border: 1px solid #e1e8ed;
  }
  
  .payment-amount {
    font-size: 1.3rem;
    color: #1a2b3c;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .payment-amount span {
    font-weight: 700;
    color: #d4af37;
    font-size: 1.5rem;
  }
  
  .qr-section {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .qr-wrapper {
    text-align: center;
  }
  
  .qr-code {
    width: 200px;
    height: 200px;
    border: 1px solid #e1e8ed;
    border-radius: 10px;
    padding: 10px;
    background: white;
  }
  
  .qr-instruction {
    margin-top: 10px;
    color: #64748b;
    font-size: 0.9rem;
  }
  
  .payment-instructions {
    background-color: #f8fafc;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
  }
  
  .payment-instructions h4 {
    color: #1a2b3c;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.1rem;
  }
  
  .payment-instructions ol {
    margin: 0;
    padding-left: 20px;
  }
  
  .payment-instructions li {
    margin-bottom: 10px;
    color: #475569;
  }
  
  .payment-button {
    background: linear-gradient(135deg, #28a745 0%, #20c997 100%);
    color: white;
    font-weight: 600;
    padding: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(40, 167, 69, 0.2);
    width: 100%;
  }
  
  .payment-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(40, 167, 69, 0.3);
  }
  
  .benefits-section {
    margin-top: 40px;
    background: white;
    border-radius: 16px;
    padding: 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  }
  
  .benefits-section h3 {
    color: #1a2b3c;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.3rem;
  }
  
  .benefits-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    padding: 0;
    list-style-type: none;
  }
  
  .benefits-list li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .benefits-list li:hover {
    background-color: #f8fafc;
    transform: translateY(-2px);
  }
  
  .check-icon {
    color: #28a745;
    font-size: 1.2rem;
    flex-shrink: 0;
  }
  
  .success-message {
    text-align: center;
    padding: 40px 20px;
    animation: fadeIn 0.5s ease;
  }
  
  .success-icon {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, #f4f4f4 0%, #72edc8 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .success-message h2 {
    color: #1a2b3c;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .success-message p {
    color: #475569;
    margin-bottom: 15px;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @media (max-width: 768px) {
    .upload-container {
      padding: 30px 20px;
    }
    
    .benefits-list {
      grid-template-columns: 1fr;
    }
    
    .payment-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
.consent-warning {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;
  background-color: #fff3e0;
  border-radius: 8px;
  border-left: 3px solid #f57c00;
  margin: 20px 0;
}

.consent-warning .info-icon {
  color: #f57c00;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.consent-warning p {
  margin: 0;
  color: #e65100;
  font-size: 1rem;
  font-weight: 500;
}

.file-upload-label.disabled {
  color: #94a3b8;
  cursor: not-allowed;
}

.file-upload-area:has(.disabled) {
  border-color: #e2e8f0;
  background-color: #f8fafc;
  opacity: 0.8;
}

.file-upload-area:has(.disabled):hover {
  border-color: #e2e8f0;
  background-color: #f8fafc;
  cursor: not-allowed;
}

input:disabled {
  background-color: #f1f5f9;
  cursor: not-allowed;
}
/* Add this to your existing CSS file */
.free-uploads-notice {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  background-color: #fff8e1;
  border-radius: 8px;
  border-left: 3px solid #f57c00;
  margin-bottom: 20px;
}

.free-uploads-notice h4 {
  color: #f57c00;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

.free-uploads-notice p {
  margin: 0;
  color: #64748b;
  font-size: 0.9rem;
}

.free-uploads-notice .info-icon {
  color: #f57c00;
  font-size: 1.2rem;
  flex-shrink: 0;
  margin-top: 3px;
}