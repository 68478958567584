.case-status-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.case-status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #e1e8ed;
  padding-bottom: 20px;
}

.case-status-header h2 {
  color: #1a2b3c;
  margin: 0;
  font-size: 1.8rem;
}

.upload-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.upload-count, .free-uploads {
  font-size: 0.9rem;
  color: #64748b;
}

.upload-count span, .free-uploads span {
  font-weight: 600;
  color: #1a2b3c;
  margin-left: 5px;
}

.paid-uploads-notice {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff3e0;
  color: #e65100;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

.no-cases {
  text-align: center;
  padding: 40px 0;
  color: #64748b;
}

.cases-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.case-card {
  background: linear-gradient(145deg, #f8fafc 0%, #f1f5f9 100%);
  border-radius: 16px;
  padding: 25px;
  border: 1px solid #e1e8ed;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.case-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.08);
}

.case-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.case-header h3 {
  color: #1a2b3c;
  margin: 0;
  font-size: 1.2rem;
}

.case-date {
  color: #64748b;
  font-size: 0.85rem;
}

.case-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.case-file, .case-phone {
  flex: 1;
  min-width: 200px;
  color: #475569;
  font-size: 0.95rem;
}

.case-status-timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  position: relative;
}

.status-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.status-icon-container {
  width: 40px;
  height: 40px;
  background-color: #f1f5f9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border: 2px solid #e1e8ed;
  transition: all 0.3s ease;
}

.status-step.active .status-icon-container {
  background-color: #d4af37;
  border-color: #d4af37;
  box-shadow: 0 0 0 4px rgba(212, 175, 55, 0.2);
}

.timeline-icon {
  color: #94a3b8;
  font-size: 1.2rem;
}

.status-step.active .timeline-icon {
  color: white;
}

.status-label {
  font-size: 0.8rem;
  color: #64748b;
  font-weight: 500;
  white-space: nowrap;
}

.status-step.active .status-label {
  color: #1a2b3c;
  font-weight: 600;
}

.status-connector {
  flex: 1;
  height: 3px;
  background-color: #e1e8ed;
  position: relative;
  z-index: 1;
}

.case-status-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: #f1f5f9;
  margin-top: 10px;
}

.status-icon {
  font-size: 1rem;
}

.status-icon.uploaded {
  color: #3498db;
}

.status-icon.payment {
  color: #d4af37;
}

.status-icon.processing {
  color: #f39c12;
}

.status-icon.objection {
  color: #e74c3c;
}

.status-icon.submitted {
  color: #2ecc71;
}

.status-icon.pending {
  color: #95a5a6;
}

.objection-details {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffebee;
  border-radius: 8px;
  border-left: 3px solid #e74c3c;
}

.objection-details h4 {
  color: #c62828;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
}

.objection-details p {
  color: #d32f2f;
  margin: 0;
  font-size: 0.95rem;
}

.payment-notice {
  margin-top: 40px;
  padding: 25px;
  background-color: #fff8e1;
  border-radius: 12px;
  border-left: 4px solid #d4af37;
  text-align: center;
}

.payment-notice h3 {
  color: #f57c00;
  margin-top: 0;
  font-size: 1.3rem;
}

.payment-notice p {
  color: #e65100;
  margin: 10px 0;
  font-size: 1rem;
}

.loading-container {
  text-align: center;
  padding: 50px;
  color: #64748b;
  font-size: 1.1rem;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 768px) {
  .case-status-container {
    padding: 20px;
  }
  
  .case-status-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .upload-stats {
    align-items: flex-start;
  }
  
  .case-status-timeline {
    overflow-x: auto;
    padding-bottom: 10px;
    justify-content: flex-start;
  }
  
  .status-step {
    min-width: 80px;
  }
}

/* Add this to the end of your CSS file */
.free-trial-badge {
  background-color: #4caf50;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  display: inline-block;
  margin-left: 10px;
}

.case-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}